import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const VasectomyProLandingPage = () => (
  <PhysicianLandingPage
    physician="TJ Morris MD"
    institution="Vasectomy Pro"
    referralCode="VASPRO"
    physicianURL="https://www.vasectomypro.com/"
  />
)

export default VasectomyProLandingPage
